// AUDIT LOG TYPES
export const AuditLogType = {
  NOTE: "NOTE",
  OFFER_UPDATE_PRICE: "OFFER_UPDATE_PRICE",
  OFFER_UPDATE_EDITORIAL: "OFFER_UPDATE_EDITORIAL",
  OFFER_UPDATE_AVAILABILITY: "OFFER_UPDATE_AVAILABILITY",
  OFFER_REASSIGNED: "OFFER_REASSIGNED",
  OFFER_NEW_MESSAGE: "OFFER_NEW_MESSAGE",
  OFFER_NEW_CONTACT: "OFFER_NEW_CONTACT",
  OFFER_NEW_REMINDER: "OFFER_NEW_REMINDER",
  MEMBER_UPDATE_PREMIUM: "MEMBER_UPDATE_PREMIUM",
  MEMBER_UPDATE_EDITORIAL: "MEMBER_UPDATE_EDITORIAL",
  MEMBER_NEW_MESSAGE: "MEMBER_NEW_MESSAGE",
  MEMBER_NEW_REMINDER: "MEMBER_NEW_REMINDER",
};

// AVAILABILITY
export const Availability = {
  PENDING: "PENDING",
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
};

export const AvailabilityReadables = {
  [Availability.PENDING]: "Pending",
  [Availability.ONLINE]: "Online",
  [Availability.OFFLINE]: "Offline",
};

// NEWSLETTER POSITIONS
export const NewsletterPosition = {
  ACCEPT: "ACCEPT",
  UNSUBSCRIBED: "UNSUBSCRIBED",
  ERRORED: "ERRORED",
  BLACKLISTED: "BLACKLISTED",
};

export const NewsletterPositionReadables = {
  [NewsletterPosition.ACCEPT]: "Accepts newsletter",
  [NewsletterPosition.UNSUBSCRIBED]: "Has unsubscribed",
  [NewsletterPosition.ERRORED]: "Rejected by emailing service",
  [NewsletterPosition.BLACKLISTED]: "Is blacklisted by Wotol",
};

/**
 * Does the newsletter position correspond to a newsletter acceptation ?
 *
 * @param {String} newsletterPosition
 * @returns {boolean}
 */
export function acceptsNewsletter(newsletterPosition) {
  return newsletterPosition === NewsletterPosition.ACCEPT;
}

// OFFER TYPES
export const OfferType = {
  MACHINE: "MACHINE",
  AUCTION: "AUCTION",
  JOB: "JOB",
  RESUME: "RESUME",
};

// RATINGS
export const Rating = {
  UNKNOWN: {
    label: "No info",
    color: "#808080",
  },
  GOOD: {
    label: "Good",
    color: "#009900",
    bgColor: "#e6ffe6",
  },
  AVERAGE: {
    label: "Average",
    color: "#e68a00",
    bgColor: "#ffcf87",
  },
  BAD: {
    label: "Bad",
    color: "#ff0000",
    bgColor: "#ffa2a2",
  },
};

export function getRatingLabel(value) {
  return Rating[value] && Rating[value].label;
}

export function getRatingColor(value) {
  return Rating[value] && Rating[value].color;
}

export function getRatingColorBackground(value) {
  return Rating[value] && Rating[value].bgColor;
}

// ROLES

export const Role = {
  POSTER: "POSTER",
  BO_ACCESS: "BO_ACCESS",
  COMMERCIAL: "COMMERCIAL",
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

export const PremiumOptionSelection = {
  SINGLE_PREMIUM_AD: "SINGLE_PREMIUM_AD",
  FREE: "FREE",
  PURCHASE_PACK: "PURCHASE_PACK",
};

// ACTIVITY
export const Activity = {
  AUCTIONER: "Auctioneer / Appraiser",
  DEALER: "Used Machinery & Equipment Dealer / Broker",
  MACHINERY_MANUFACTURER: "Manufacturer (Machinery and Equipment)",
  PRODUCT_MANUFACTURER: "Manufacturer (Finished Products)",
  DISTRIBUTOR: "Distributor (Machinery and Equipment)",
  SERVICE: "Service and Repair",
  REBUILDER: "Rebuilder and Retrofitter",
  ENGINEERING: "Engineering Services",
  CUSTOMS: "Customs Broker / Freight Forwarder",
  SHIPPER_MOVER: "Shipping / Chartering / Trucking / Freight Mover",
  FINANCING: "Financing and Leasing Company",
  TRADE_SHOWS: "Trade Shows, Trade Shows Organizer",
  PUBLISHING: "Magazines, Publishing Company",
  OTHERS: "Others",
};

export function getActivityLabel(value) {
  return Activity[value] || null;
}
